<template>
    <section>
        <b-loading :active.sync="carregando"></b-loading>

        <titlebar :title-stack="titleStack">
           
        </titlebar>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical is-9">
                <article class="tile is-child box">

                    <div class="columns">
                        <div class="column is-2">
                            <ValidationProvider rules="required" name="apelido" v-slot="{ errors, valid }">
                                <b-field label="Apelido"
                                    ref="apelido"
                                            :type="{ 'is-danger': errors[0] || apelidoInvalido, 'is-success': valid }"
                                            :message="getErrosApelido(errors)">
                                    <b-input type="text" v-model="model.apelido" maxlength="10" :has-counter="false" @blur="verificaApelidoExame"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>         
                        <div class="column">
                            <ValidationProvider rules="required" name="nome" v-slot="{ errors, valid }">
                                <b-field label="Nome"
                                            :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                            :message="errors">
                                    <b-input type="text" v-model="model.nome" maxlength="150" :has-counter="false"></b-input>
                                </b-field>
                            </ValidationProvider>
                        </div>                                                                                                              
                    </div>

                    <div class="columns">
                        <div class="column">
                                <b-field label="Setor">
                                <b-select v-model="model.setor.id" expanded>
                                    <option
                                        v-for="option in setores"
                                        :value="option.value"
                                        :key="option.value">
                                        {{ option.value }} - {{ option.text }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>

                        <div class="column">
                                <b-field label="Etiqueta número" expanded>
                                    <b-tooltip expanded label="Clique na seta para buscar o próximo valor não utilizado"
                                    type="is-dark"
                                    position="is-top" >
                                    <b-input type="number" icon="forward" v-model="model.agrupamentoDeAmostrasNumero" icon-clickable @icon-click="buscarProximoNumeroEtiqueta" expanded></b-input>                                            
                                </b-tooltip>
                            </b-field>
                        </div>

                        <div class="column">
                                <b-field label="Ordem no resultado" expanded>
                                    <b-tooltip expanded label="Clique na seta para buscar o próximo valor não utilizado"
                                    type="is-dark"
                                    position="is-top" >
                                    <b-input type="number" icon="forward" v-model="model.ordemNoResultado" icon-clickable @icon-click="buscarProximoNumeroOrdemRestado" expanded></b-input>                                            
                                </b-tooltip>
                            </b-field>
                        </div>                                
                    </div>

                    <div class="columns">
                        <div class="column">
                                <b-field label="Qtde. amostras">
                                <b-input type="number" v-model="model.quantidadeDeAmostras" max="99999"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                                <b-field label="Qtde. etiquetas">
                                <b-input type="number" v-model="model.quantidadeDeViasDeEtiqueta" max="99999"></b-input>
                            </b-field>
                        </div>                                
                        <div class="column">
                                <b-field label="Prazo dias">
                                <b-input type="number" v-model="model.prazoDeEntrega" max="99999"></b-input>
                            </b-field>
                        </div>  
                        <div class="column">
                                <b-field label="Prazo horas">
                                <b-input type="number" v-model="model.prazoDeEntregaHoras" max="99999"></b-input>
                            </b-field>
                        </div>                                                           
                        <div class="column">
                                <b-field label="Prazo minutos">
                                <b-input type="number" v-model="model.prazoDeEntregaMinutos" max="99999"></b-input>
                            </b-field>
                        </div>                                                                       
                    </div>

                    <div class="columns">
                        <div class="column">
                            <b-field label="Tempo mínimo de jejum">
                                <b-input type="number" v-model="model.minimoJejum" max="99999"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Volume desejado">
                                <b-input v-model="model.volumeDesejado"  maxlength="150" :has-counter="false"></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field label="Volume mínimo">
                                <b-input v-model="model.volumeMinimo"  maxlength="150" :has-counter="false"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <b-field label="Sinonímia">
                                <b-input v-model="model.sinonimia"  maxlength="1000" :has-counter="false"></b-input>
                            </b-field>
                        </div>
                    </div>          

                    
                </article>
                                    
                <article class="tile is-child box">
                    <cadastro-generico :colunas="colunasRecipiente" label="Recipientes" :items.sync="model.recipientes"></cadastro-generico>
                </article>

                <article class="tile is-child box">
                    <div class="panel-heading has-text-centered">
                            Dias de realizaçăo
                    </div>
                    <div class="panel-block tile is-12">
                        <b-checkbox-button v-model="diasDaSemana['0']" :native-value="true">Domingo</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['1']" :native-value="true">Segunda</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['2']" :native-value="true">Terça</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['3']" :native-value="true">Quarta</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['4']" :native-value="true">Quinta</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['5']" :native-value="true">Sexta</b-checkbox-button>
                        <b-checkbox-button v-model="diasDaSemana['6']" :native-value="true">Sábado</b-checkbox-button>                                
                    </div>
                </article>

                <article class="tile is-child box">
                    <div class="panel-heading has-text-centered">
                            Obtenção de amostra
                    </div>
                    <div class="panel-block column">
                        <div class="column">
                            <b-field label="Informações ao laboratório">
                                <b-input v-model="model.informacoesAoLaboratorio" type="textarea" expanded/>
                            </b-field>                               
                        </div>
                        <div class="column">
                            <b-field label="Informações ao paciente">
                                <b-input v-model="model.informacoesAoPaciente" type="textarea" expanded/>
                            </b-field>                               
                        </div>
                    </div>
                </article>
                <article class="tile is-child box">
                    <div class="panel-heading has-text-centered">
                            Rascunho de bancada
                    </div>
                    <div class="panel-block column">
                        <div class="column">
                            <b-field label="">
                                <b-input v-model="model.rascunho" type="textarea" expanded/>
                            </b-field>                               
                        </div>
                    </div>
                </article>      
                <article class="tile is-child box">
                    <div class="panel-heading has-text-centered">
                            POP
                    </div>
                    <div class="panel-block column">
                        <div class="column">
                            <b-field label="">
                                <b-input v-model="model.pop" type="textarea" expanded/>
                            </b-field>                               
                        </div>
                    </div>
                </article>
                <article class="tile is-child box">
                    <div class="panel-heading has-text-centered">
                            Aplicaçăo clínica / Interpretaçăo
                    </div>
                    <div class="panel-block column">
                        <div class="column">
                            <b-field label="">
                                <b-input v-model="model.aplicacaoClinica" type="textarea" expanded/>
                            </b-field>                               
                        </div>
                    </div>
                </article>   
                <article class="tile is-child box">
                    <div class="panel-heading has-text-centered">
                            Critérios de rejeiçăo
                    </div>
                    <div class="panel-block column">
                        <div class="column">
                            <b-field label="">
                                <b-input v-model="model.criteriosRejeicao" type="textarea" expanded/>
                            </b-field>                               
                        </div>
                    </div>
                </article>

                <article class="tile is-child box">
                        <cadastro-generico label="Consumiveis do exame" 
                            :colunas="colunasConsumiveisExames"  
                            :items.sync="model.consumiveisExame"
                            total="true" colunaTotal="subtotal"
                            ></cadastro-generico>
                </article> 

                <article class="tile is-child box">
                        <cadastro-generico label="Pesquisa clínica" 
                            :colunas="colunasPesquisaClinica"  
                            :items.sync="model.pesquisas"
                            ></cadastro-generico>
                </article>
                
                <article class="tile is-child box" v-show="model.id">
                        <cadastro-generico label="Exames complementares" 
                            :colunas="colunasExamesComplementares"  
                            :items.sync="model.examesComplementares"                            
                            ></cadastro-generico>
                </article>  

                <article class="tile is-child box" v-if="isInRole('tabelapreço-alterar')">
                    <b-collapse class="card has-background-transparent"
                        animation="slide"
                        :open="isOpen"
                        @open="isOpen = !isOpen">
                        <div slot="trigger"
                            slot-scope="props"
                            class="card-header has-background-light has-text-centered"
                            role="button">
                            <p class="card-header-title">
                                Tabelas de preços
                            </p>
                            <a class="card-header-icon">
                                <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                                <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                            </a>
                        </div>
                        <div class="panel-block column">
                            <p class="has-text-centered">
                                <b-button v-if="!bloqueado" type="is-warning" icon-left="lock" @click="bloquearExames()">
                                    Bloquear exame
                                </b-button>
                                <b-button v-else type="is-primary" icon-left="lock-open" @click="desbloquearExames()">
                                    Desbloquear exame
                                </b-button>
                            </p>
                            <b-table v-if="model.precos.length != 0" :data="model.precos"
                                        :loading="carregando"
                                        striped
                                        hoverable>

                                <template slot-scope="props">
                                    <b-table-column field="Nome" label="Nome">
                                        {{ props.row.tabelaPreco.nome }}
                                    </b-table-column>

                                    <b-table-column field="Moeda" label="Valor">
                                        <input v-if="props.row.tabelaPreco.tipo == 'Moeda'" type="number" step=".00000001" class="input" v-model="props.row.valor" name="Preco" />
                                        <input v-if="props.row.tabelaPreco.tipo == 'AMB'" type="number" step=".00000001" class="input" v-model="props.row.valor" name="Preco" />

                                        <div class="tabela-cbhpm" v-if="props.row.tabelaPreco.tipo == 'CBHPM'">
                                            <input type="text" class="input" v-model="props.row.percentualPorte" name="PercentualPorte" />
                                            <input type="text" class="input" v-model="props.row.custoOperacional" name="CustoOperacional" />
                                            <b-select class="is-fullwidth" v-model="props.row.valorCBHPM">
                                                <option v-for="valorCBHPM in model.valoresCBHPM.id"
                                                        :key="valorCBHPM.id"
                                                        :value="valorCBHPM.id">
                                                    {{ valorCBHPM.valor }}
                                                </option>
                                            </b-select>
                                        </div>

                                    </b-table-column>

                                    <b-table-column field="Bloqueado" label="Bloqueado" centered>
                                        <b-checkbox class="level-item" v-model="props.row.bloqueado">
                                        </b-checkbox>
                                        <b-field>
                                            <b-input v-if="props.row.bloqueado" placeholder="Motivo do bloqueio"
                                                        v-model="props.row.motivoBloqueio"></b-input>
                                        </b-field>
                                    </b-table-column>

                                    <b-table-column field="RequerAutorizacao" label="Autorização" centered>
                                        <b-checkbox v-model="props.row.requerAutorizacao">
                                        </b-checkbox>
                                    </b-table-column>

                                    <b-table-column field="ValorNegociado" label="Negociado" centered>
                                        <b-checkbox v-model="props.row.valorNegociado">
                                        </b-checkbox>
                                    </b-table-column>
                                </template>
                            </b-table>
                        </div>
                    </b-collapse>
                </article>                                                                                                                                                          
            </div>


            
            <div class="tile is-parent">
                <article class="tile is-child">    
                        <article class="panel">
                        <div class="panel-block">
                            <b-switch v-model="model.selecionaVersaoDaMascara">
                                Seleciona versão da máscara ao digitar o resultado
                            </b-switch>
                        </div>
                        <div class="panel-block">
                            <b-switch v-model="model.naoPublicaResultadosNaInternet">
                                Não publica resultados na internet
                            </b-switch>
                        </div>    
                        <div class="panel-block">
                            <b-switch v-model="model.caraterPlantao">
                                Realizado em caráter de plantão
                            </b-switch>
                        </div>  
                        <div class="panel-block">
                            <b-switch v-model="model.naoExibirLaudoWebPendente">
                                Exame de faturamento
                            </b-switch>
                        </div>                      
                        <div class="panel-block">
                            <b-switch v-model="model.informarMedicamento">
                                Informar uso medicamento
                            </b-switch>
                        </div>                          
                        <div class="panel-block">
                            <b-switch v-model="model.informarDUM">
                                Informar data última menstruação
                            </b-switch>
                        </div>  
                        <div class="panel-block">
                            <b-switch v-model="model.informarPeso">
                                Informar peso do paciente
                            </b-switch>
                        </div>  
                        <div class="panel-block">
                            <b-switch v-model="model.informarAltura">
                                Informar altura do paciente
                            </b-switch>
                        </div>
                        <div class="panel-block">
                            <b-switch v-model="model.resultadoParcial">
                                Liberar resultado parcial
                            </b-switch>
                        </div>     
                        <div class="panel-block">
                            <b-switch v-model="model.desativado" type="is-danger">
                                Desativado
                            </b-switch>
                        </div>       
                        </article>   
                    <article class="panel" v-if="model.id">
                        <div class="panel-block">
                            <a :href="'/Manutencao/Mascara/' + model.id" class="button is-info is-fullwidth">Editar máscara de resultado</a>                                    
                        </div>
                        <div class="panel-block">
                            <router-link :to="{ name: 'liberacaoautomatica', params: {id: model.apelido} }" class="button is-primary is-fullwidth">
                                Editar liberação automática
                            </router-link>
                        </div>                               
                    </article>         
                    <article class="panel">
                        <historico-alteracoes :tabela="'Exame'" :chave="model.id"></historico-alteracoes>
                    </article>
                    <article class="panel">
                        <div class="panel-heading has-text-centered">
                            Códigos integrações
                        </div>
                        <div class="panel-block">
                            <table class="table is-bordered is-striped is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Código</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in model.codigos" v-bind:key="index">
                                        <td>
                                            {{item.exameCodigoTipo.nome}}
                                        </td>
                                        <td>
                                            <input class="input" type="text" v-model="item.codigo">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>                               
                    </article>
                </article>
            </div>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                                v-show="isInRole('exame-alterar')"
                                :loading="salvandoDados"
                                @click="onSubmit()"
                                icon-left="check-circle">
                        Salvar
                    </b-button>
                    <b-button type="is-danger"
                                native-type="button"
                                icon-left="close-circle"
                                @click="$router.back();">
                        Cancelar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>
<style lang="scss" scoped>
    .is-expanded{
        width: 100%;
        span {
            width: 100%;
        }
    }
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import cadastroGenerico from '@/components/cadastroGenerico.vue'
    
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    agrupamentoDeAmostrasNumero: null,
                    apelido: null,
                    aplicacaoClinica: null,
                    caraterPlantao: false,
                    codigoIntegracao: null,
                    codigos: [], //{exameCodigoTipo: {id: 3, nome: "DCNET ESP"}, codigo: "1014"}
                    consumiveisExame: [], //{id: 2, consumivel: {id: 44, nome: "Teste Treinamento"}, quantidade: 1, custoUnitario: 2}
                    criteriosRejeicao: null,
                    diasRealizacao: null,
                    examesComplementares: [],
                    informacoesAoLaboratorio: null,
                    informacoesAoPaciente: null,
                    informarAltura: false,
                    informarDUM: false,
                    informarMedicamento: false,
                    informarPeso: false,
                    mascaras: [], //{nome: "1", versao: 1, exame: null, mascaraHtml: null, mascaraHtmlUltimaModificacao: null,…}
                    minimoJejum: null,
                    naoExibirLaudoWebPendente: false,
                    naoPublicaResultadosNaInternet: false,
                    nome: null,
                    ordemNoResultado: null,
                    pesquisas: [], //{id: 7, nome: "Documentos do Paciente"}
                    pop: null,
                    prazoDeEntrega: null,
                    prazoDeEntregaHoras: null,
                    prazoDeEntregaMinutos: null,
                    precos: [], //{tabelaPreco: {id: 4, nome: "AMB", tipo: "AMB", valoresCBHPM: null}
                    quantidadeDeAmostras: null,
                    quantidadeDeViasDeEtiqueta: null,
                    rascunho: null,
                    recipientes: [], //{id: 5001, material: {id: 2, nome: "Soro", codigosIntegrador: null}
                    resultadoParcial: false,
                    selecionaVersaoDaMascara: false,
                    setor: {id: 0, nome: null},
                    sinonimia: null,
                    volumeDesejado: null,
                    volumeMinimo: null,
                    codigosIntegrador: [],
                },
                isOpen: false,
                setores: [],
                salvandoDados: false,
                colunasRecipiente: [
                    {valor: 'id', label: 'Código', readonly: true, valorPadrao: 0 },
                    {valor: 'material', label: 'Material', tipo: 'select', opcoes: [] },
                    {valor: 'conservante', label: 'Conservante', tipo: 'select', opcoes: [] },
                    {valor: 'estabilidadeAmbiente', label: 'Estabilidade Ambiente (h)', tipo: 'numero', valorPadrao: 0},
                    {valor: 'estabilidadeRefrigerado', label: 'Estabilidade Refrigerado (h)', tipo: 'numero', valorPadrao: 0},
                    {valor: 'estabilidadeCongelado', label: 'Estabilidade Congelado (h)', tipo: 'numero', valorPadrao: 0}
                ],
                colunasConsumiveisExames: [
                    {valor: 'consumivel', label: 'Consumivel', colunas: [
                        {valor: 'id', label: 'Id do Consumível', tipo:"numero", carregarCampo: "nome"}
                        ,{valor: 'nome', label: 'Nome', tipo: 'autocomplete'}]
                        , dinamica: true
                        , tabela: 'Consumivel'
                    },                    
                    {valor:'status', label: 'Status', tipo:'select', opcoes: [] },
                    {valor:'compartilhado', label: 'Compartilhado', tipo:'select', opcoes: [] },
                    {valor:'quantidade', label: 'Quantidade', tipo:"numero", passos:"0.1"},
                    {valor:'custoUnitario', label: 'Preço Unitário', tipo:"numero", passos:"0.1"},
                    {valor:'subtotal', label: 'Subtotal', tipo:"subtotal", fn: (row) => {
                        return row.custoUnitario * row.quantidade;
                    }}
                ],
                colunasPesquisaClinica: [
                    {valor: 'id', label: 'Id da Pesquisa', tipo:"numero", carregarCampo: "nome", carregarCampoId: 'id', tabela: 'Pesquisa'},
                    {valor: 'nome', label: 'Nome', tipo:"autocomplete", dinamica: true, tabela: "Pesquisa"}
                ],
                colunasExamesComplementares: [
                    {valor: 'id', label: 'Id do Exame', tipo:"numero", carregarCampo: "nome", carregarCampoId: 'id', tabela: 'Exame', filtroFixo: {exameId: this.model?.id}, campoIdComponente: 'id'},
                    {valor: 'nome', label: 'Nome', tipo:"autocomplete", dinamica: true, tabela: "Exame"}
                ],
                carregando: false,
                diasDaSemana: {
                    '0': false,
                    '1': false,
                    '2': false,
                    '3': false,
                    '4': false,
                    '5': false,
                    '6': false,
                },
                apelidoInvalido: false,
                bloqueado: false
            };
        },
        components: {
            titlebar,
            cadastroGenerico,
            historicoAlteracoes
        },
        created() {

            try {
                this.carregando = true;
                this.$http.get('/api/manutencao/setores?all=true')
                                .then(res => {
                                    this.setores = res.data;
                            }).catch(e => {
                                this.carregando = false;
                                throw e;

                            });
                            const id = this.$route.params.id ? this.$route.params.id : '';
                            const colunaExameComplementarFiltroFixo = this.colunasExamesComplementares?.find(c => c.carregarCampo);
                            if (colunaExameComplementarFiltroFixo){
                                colunaExameComplementarFiltroFixo.filtroFixo = {
                                    exameId: id
                                }
                            }
                this.$http.get('/api/manutencao/exame?id=' + id)
                    .then(res => {
                        const dados = {...res.data};
                        dados.recipientes = dados.recipientes?.map(r => {
                            return {
                                ...r,
                                conservanteId: r.conservante?.id
                            }
                        })
                        this.model = dados;
                        const material = this.colunasRecipiente.find(r => r.valor === 'material');
                        if (material){
                            material.opcoes = this.model.materiais;
                        }
                        const conservante = this.colunasRecipiente.find(r => r.valor === 'conservante');
                        if (conservante){
                            conservante.opcoes = this.model.conservantes
                        }
                        //Força a recarga das colunas no componente
                        this.colunasRecipiente = [...this.colunasRecipiente];
                        
                        this.model.consumiveisExame = this.model.consumiveisExame?.map(c => {
                            return {
                                ...c,
                                // eslint-disable-next-line
                                consumivel__id: c.consumivel?.id,
                                // eslint-disable-next-line
                                consumivel__nome: c.consumivel?.nome
                            }
                        });

                        this.$http.get('/api/manutencao/ConsumiveisExamesCompartilhado')
                            .then(res => {
                                const consumivelExameCompartilhado = this.colunasConsumiveisExames.find(r => r.valor === 'compartilhado');
                                if (consumivelExameCompartilhado){
                                    consumivelExameCompartilhado.opcoes = res.data.map(item => {
                                        return {
                                            id: item.id,
                                            nome: item.nome
                                        }
                                    });
                                }
                                //Força a recarga das colunas no componente
                                this.colunasConsumiveisExames = [...this.colunasConsumiveisExames];
                            })

                        this.$http.get('/api/manutencao/consumiveisExamesStatus')
                            .then(res => {
                                const status = this.colunasConsumiveisExames.find(r => r.valor === 'status');
                                if (status){
                                    status.opcoes = res.data.map(item => {
                                        return {
                                            id: item.id,
                                            nome: item.nome
                                        }
                                    });
                                }
                                //Força a recarga das colunas no componente
                                this.colunasConsumiveisExames = [...this.colunasConsumiveisExames];
                            })



                        if (this.model.diasRealizacao){
                            this.model.diasRealizacao?.forEach(d => {
                                this.diasDaSemana[d?.toString()] = true;
                            });
                        }else{
                            Object.keys(this.diasDaSemana).forEach(k => {
                                this.diasDaSemana[k] = true;
                            })
                        }

                        delete this.model.mascaras;

                        this.model.precos = this.model.precos?.map(p => {

                            p.valor = parseFloat(p.valor).toFixed(2);

                            if(isNaN(p.valor)){
                                p.valor = 0;
                            }

                            if (!this.model.id){
                                delete p.exame;
                                return p;
                            }else{
                                return {
                                    ...p,
                                    exame: {
                                        id: this.model.id ,
                                        nome: this.model.nome ,
                                        apelido: this.model.apelido,
                                    }
                                }
                            }
                        })

                        this.$nextTick(() => {
                            this.$refs.apelido.$el.getElementsByClassName('input')[0].focus()
                        });


                }).catch(e => {
                    this.carregando = false;
                    throw e;

                });                
            }finally{
                this.carregando = false;
            }
            
        },
        computed: {
            titleStack() {
                return [
                    'Manutenção',
                    'Exame',
                    (this.model.id && this.model.id != 0) ? this.model.id : 'novo'
                ]
            },          
            ...mapState([
                'unidadeId',
                'config',
                'status',
                'integradores'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
          
        },
        methods: {
            onSubmit() {
                const newItem = Object.assign({}, this.model);
                if (!this.$route.params.id){
                    delete newItem.id;
                }

                const adicionarExameAosItens = (nomeArray, variavel) => {
                    if (variavel[nomeArray]){
                        variavel[nomeArray] = variavel[nomeArray]?.map(c => {
                            return {
                                ...c,
                                exame: {id: this.model.id, nome: this.model.nome, apelido: this.model.apelido}
                            }
                        })
                    }
                }

                adicionarExameAosItens('consumiveisExame', newItem);
                adicionarExameAosItens('examesComplementares', newItem);
                adicionarExameAosItens('pesquisas', newItem);
                adicionarExameAosItens('recipientes', newItem);                

                if (newItem.codigosIntegrador){
                    newItem.codigosIntegrador = newItem.codigosIntegrador.map(i => {
                        return {
                            codigo: i.codigo,
                            integradorExameId: {
                                codigo: i.codigo,
                                integradorId: i.integradorId
                            }
                        }
                    }).filter(i => i.integradorExame?.integradorId?.toString()?.trim());
                }

                newItem.examesComplementares = newItem.examesComplementares?.map(e => {
                    return e['__resultado'];
                })

                let diasDaSemana = '';
                const addToDiasDaSemana = (valor) => {
                    if (!diasDaSemana.length) diasDaSemana = '?';
                    else diasDaSemana += '&';

                    diasDaSemana += valor;
                }
                
                if (this.diasDaSemana['0']) addToDiasDaSemana('domingo=true');
                if (this.diasDaSemana['1']) addToDiasDaSemana('segunda=true');
                if (this.diasDaSemana['2']) addToDiasDaSemana('terca=true');
                if (this.diasDaSemana['3']) addToDiasDaSemana('quarta=true');
                if (this.diasDaSemana['4']) addToDiasDaSemana('quinta=true');
                if (this.diasDaSemana['5']) addToDiasDaSemana('sexta=true');
                if (this.diasDaSemana['6']) addToDiasDaSemana('sabado=true');

                delete newItem.diasRealizacao;

                //desconsiderando valores com id null para consumiveis, pesquisas e exames complementares
                newItem.consumiveisExame = newItem.consumiveisExame?.filter(c => c.consumivel?.id != null); 
                newItem.pesquisas = newItem.pesquisas?.filter(c => c.id != null); 
                newItem.examesComplementares = newItem.examesComplementares?.filter(c => c.id != null); 

                //Formatando os valores de recipientes e consumiveis para que sejam 0 caso estejam null
                newItem.recipientes = newItem.recipientes?.map(r => {
                    if (!r.estabilidadeAmbiente) r.estabilidadeAmbiente = 0;
                    if (!r.estabilidadeRefrigerado) r.estabilidadeRefrigerado = 0;
                    if (!r.estabilidadeCongelado) r.estabilidadeCongelado = 0;
                    
                    return r;
                });

                newItem.consumiveisExame = newItem.consumiveisExame?.map(r => {

                    if (!r.custoUnitario) r.custoUnitario = 0;
                    if (!r.quantidade) r.quantidade = 0;
                    
                    r.status = r.status.id;
                    r.compartilhado = r.compartilhado.id;

                    return r;
                });

                if(!this.apelidoInvalido){

                    this.salvandoDados = true
                    this.$http.post(`/api/manutencao/exame${diasDaSemana}`, newItem)
                        .then((res) => {
                            if (res.data && !this.model.id){
                                this.abrir(`/Manutencao/Mascara/${res.data}`);
                            }else{
                                this.$router.back()
                            }
                        })
                        .catch(err => {
                            Object.keys(err.body.errors).forEach(x => {
                                this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                            })
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: 'Erro ao salvar exame',
                                type: 'is-danger',
                                queue: false
                            })
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        })
                        .finally(() => {
                            this.salvandoDados = false
                        })                    

                }
            },
            addCodigo() {
                this.model.codigosIntegrador.push({
                    integradorId: null,
                    codigo: null
                });
            },
            buscarProximoNumeroEtiqueta(){
                this.$http.get('/api/manutencao/proximoEtiquetaNumeroExame?setorId=' + this.model.setor?.id + `&_=${new Date().getTime()}`)
                .then(res => {
                    if (res.data != null){
                        this.model.agrupamentoDeAmostrasNumero = res.data;
                    }

                });

            },
            buscarProximoNumeroOrdemRestado(){
                 this.$http.get('/api/manutencao/proximoOrdemNoResultado?setorId=' + this.model.setor?.id + `&_=${new Date().getTime()}`)
                .then(res => {
                    if (res.data != null){
                        this.model.ordemNoResultado = res.data;
                    }

                });

            },
            getErrosApelido(erros){
                if (this.apelidoInvalido){
                    erros = [...erros,'O apelido já existe, tente outro por favor.']
                }
                return erros;

            },
            verificaApelidoExame(apelido){
                this.apelidoInvalido = false;

                 this.$http.get(`/api/manutencao/VerificaApelidoExame?exameId=${this.model.id}&exameApelido=${encodeURIComponent(apelido.target.value)}`)
                .then(res => {
                    if (!res.data?.valido){
                        this.apelidoInvalido = true;
                        this.$forceUpdate();
                    }
                });                
            },
            abrir(url){
                window.location = url;
            },
            bloquearExames(){
                this.model.precos.forEach(examePreco => examePreco.bloqueado = true);
                this.bloqueado = true;
            },
            desbloquearExames(){
                this.model.precos.forEach(examePreco => examePreco.bloqueado = false);
                this.bloqueado = false;
            }
        }
    }
</script>