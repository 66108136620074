<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <b-notification type="is-warning">
            <b>Atenção!</b>
            As alterações só surtirão efeito na próxima conexão do interface com o equipamento.
        </b-notification>
        <div class="box">
            <div class="columns is-multiline is-mobile">
                <div class="column is-full-mobile">
                    <b-field label="Nome">
                        <b-input type="text" v-model="model.applicationName"></b-input>
                    </b-field>
                </div>
                <div class="column is-half-mobile">
                    <b-field label="Instalação">
                        <b-input type="text" v-model="model.facilityName"></b-input>
                    </b-field>
                </div>
                <div class="column is-half-mobile">
                    <b-field label="Versão">
                        <b-input type="text" v-model="model.version"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns is-multiline is-mobile">
                <div class="column is-full-mobile">
                    <b-field label="Tipo de conexão">
                        <b-select v-model="model.connectionType">
                            <option v-for="option, index in connectionTypes"
                                    :value="option[0]"
                                    :key="index">
                                {{ option[1] }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <template v-if="model.connectionType == 0">
                    <div class="column is-half-mobile">
                        <b-field label="Porta">
                            <b-input v-model="model.serialPortName"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field label="Bits/segundo">
                            <b-input v-model="model.serialBaudRate"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field label="Paridade">
                            <b-select v-model="model.serialParity">
                                <option v-for="option, index in paridades"
                                        :value="option[0]"
                                        :key="index">
                                    {{ option[1] }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field label="Bits de dados">
                            <b-input v-model="model.serialDataBits"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field label="Bits de parada">
                            <b-input v-model="model.serialStopBits"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field label="Controle de fluxo">
                            <b-select v-model="model.serialHandshake">
                                <option v-for="option, index in handshakes"
                                        :value="option[0]"
                                        :key="index">
                                    {{ option[1] }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </template>
                <template v-if="model.connectionType == 1 || model.connectionType == 3">
                    <div class="column is-4">
                        <b-field label="Porta">
                            <b-input v-model="model.tcpPortNumber"></b-input>
                        </b-field>
                    </div>
                </template>
                <template v-if="model.connectionType == 2">
                    <div class="column is-full-mobile">
                        <b-field label="Diretório de importação">
                            <b-input v-model="model.fileImportDir"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-full-mobile">
                        <b-field label="Diretório de exportação">
                            <b-input v-model="model.fileExportFullPath"></b-input>
                        </b-field>
                    </div>
                </template>
            </div>
            <hr />
            <div class="columns">
                <div class="column">
                    <b-field>
                        <b-checkbox v-model="model.sendResultUnits">
                            Enviar resultado com unidade recebida
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.autoSendResultsWithoutConfirm">
                            Enviar automaticamente <b>TODOS</b> os resultados (sem confirmação)
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.dontOverwriteSignedResults">
                            Não sobrepor resultados já assinados
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.sampleLink">
                            Equipamento envia resultados sem amostra vinculada
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.autoSendNormalResults">
                            Enviar automaticamente resultados com valor normal:
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-input placeholder="Valor normal" v-model="model.normalInterpretationCode" :disabled="!model.autoSendNormalResults"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field>
                        <b-checkbox v-model="model.reSendOnlyMissingTests">
                            Re-enviar somente exames pendentes de digitação
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.autoReSendAllTests">
                            Re-enviar todos os exames da amostra
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.dontShowComments">
                            Não mostrar comentários recebidos na confirmação
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.ignoreBlankResults">
                            Ignorar resultados em branco
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-checkbox v-model="model.ignoreAndRepeatResultsWith">
                            Ignorar e repetir (voltar para pendência) resultados com valor:
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <b-input placeholder="Valor de repetição" v-model="model.ignoreAndRepeatResultsWithVal" :disabled="!model.ignoreAndRepeatResultsWith"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-2">
                    <b-field>
                        <b-checkbox v-model="model.sumOnConfirmation">
                            Totalizador:
                        </b-checkbox>
                    </b-field>
                </div>
                <div class="column">
                    <b-field>
                        <b-input placeholder="Título" v-model="model.sumOnConfirmationTitle" :disabled="!model.sumOnConfirmation"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field>
                        <b-input placeholder="Fórmula" v-model="model.sumOnConfirmationFormula" :disabled="!model.sumOnConfirmation"></b-input>
                    </b-field>
                </div>
            </div>
            <hr />
            <div class="panel-heading">
                Exames
            </div>
            <div v-for="item, index in model.testCodeConfig" :key="index">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field label="Código">
                            <b-input v-model="item.testCode"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field label="Nome">
                            <b-input v-model="item.testName"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field label="Código do exame">
                            <b-input v-model="item.lisTestCode" @blur="getExameId($event, item)"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field label="Nome do exame">
                            <b-autocomplete :data="listaExames"
                                            field="nome"
                                            v-model="item.lisTestName"
                                            :loading="isFetchingListaExames"
                                            @typing="getAsyncExames($event, item)"
                                            @select="selectExame($event, item)">
                                <template slot="empty">
                                    Nenhum exame encontrado
                                </template>
                                <template slot-scope="props">
                                    <div class="is-flex is-justified-between">
                                        <span class="tag is-black">{{ props.option.apelido }}</span>
                                        <span>{{ props.option.nome }}</span>
                                        <span class="tag">{{ props.option.id }}</span>
                                    </div>
                                </template>
                            </b-autocomplete>
                        </b-field>
                    </div>
                </div>
                <div class="columns is-mobile is-multiline">
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field label="Código do campo">
                            <b-input v-model="item.lisResultCode" @blur="getMascaraCampoId($event, item)"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field label="Nome do campo">
                            <b-autocomplete :data="listaCampos"
                                            field="nome"
                                            v-model="item.lisResultName"
                                            :loading="isFetchingListaCampos"
                                            @typing="getAsyncCampos($event, item)"
                                            @select="selectCampo($event, item)">
                                <template slot="empty">
                                    Nenhum campo encontrado
                                </template>
                                <template slot-scope="props">
                                    <div class="is-flex is-justified-between">
                                        <div>
                                            <span class="tag is-black" title="Apelido do exame">{{ props.option.exameApelido }}</span>
                                            <span class="tag is-info" title="Versão da máscara">{{ props.option.mascaraVersao }}</span>
                                        </div>
                                        <span>{{ props.option.nome }}</span>
                                        <span class="tag">{{ props.option.id }}</span>
                                    </div>
                                </template>
                            </b-autocomplete>
                        </b-field>
                    </div>
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field label="Versão da máscara">
                            <b-input v-model="item.lisResultVersion"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field label="Amostra número">
                            <b-input v-model="item.sampleNumber"></b-input>
                        </b-field>
                    </div>
                </div>
                <div class="columns is-mobile is-multiline">
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field label="Decimais">
                            <b-input v-model="item.decimals" numeric></b-input>
                        </b-field>
                    </div>
                    <div class="column is-2-desktop is-half-mobile">
                        <b-field label="Unidade">
                            <b-input v-model="item.units"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6-desktop is-full-mobile">
                        <b-field label="Fórmula para cálculo">
                            <b-input v-model="item.calculationFormula"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-1-desktop is-half-mobile">
                        <br>
                        <b-field>
                            <b-checkbox v-model="item.receiveOnly">
                                Só recebe
                            </b-checkbox>
                        </b-field>
                    </div>
                    <div class="column is-1-desktop is-half-mobile">
                        <br>
                        <b-field>
                            <b-checkbox v-model="item.sendSigned">
                                Envia assinado
                            </b-checkbox>
                        </b-field>
                    </div>
                </div>
                <hr />
            </div>
            <p class="has-text-centered">
                <b-button icon-left="plus" @click="model.testCodeConfig.push({})">Incluir exame</b-button>
            </p>
            <hr />
            <div class="panel-heading">
                Materiais
            </div>
            <div v-for="item, index in model.specimenSource" :key="index">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-half-mobile">
                        <b-field label="Nome">
                            <b-input v-model="item.name"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-half-mobile">
                        <b-field label="Nome do material">
                            <b-autocomplete :data="listaMateriais"
                                            field="nome"
                                            v-model="item.lisName"
                                            :loading="isFetchingListaMateriais"
                                            @typing="getAsyncMateriais($event, item)"
                                            @select="selectMaterial($event, item)">
                                <template slot="empty">
                                    Nenhum material encontrado
                                </template>
                                <template slot-scope="props">
                                    <div class="is-flex is-justified-between">
                                        <span>{{ props.option.nome }}</span>
                                        <span class="tag">{{ props.option.id }}</span>
                                    </div>
                                </template>
                            </b-autocomplete>
                        </b-field>
                    </div>
                </div>
                <hr />
            </div>
            <p class="has-text-centered">
                <b-button icon-left="plus" @click="model.specimenSource.push({})">Incluir material</b-button>
            </p>
        </div>
        <nav class="level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-success"
                              :loading="salvandoDados"
                              native-type="submit"
                              @click="submit"
                              icon-left="check-circle">
                        Salvar
                    </b-button>
                    <b-button type="is-danger"
                              native-type="button"
                              icon-left="close-circle"
                              @click="$router.back();">
                        Cancelar
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>

<script>
    import debounce from 'lodash/debounce'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: {
                    id: null,
                    testCodeConfig: [{
                        lisResultCode: null,
                        lisResultName: null,
                        lisTestCode: null,
                        lisTestName: null,
                        lisResultVersion: null,
                        sampleNumber: null,
                        decimals: null,
                        units: null
                    }],
                    specimenSource: [{
                        id: null,
                        name: null,
                        lisname: null
                    }]
                },

                isFetchingListaCampos: false,
                isFetchingListaExames: false,
                isFetchingListaMateriais: false,

                listaCampos: [],
                listaExames: [],
                listaMateriais: [],

                removerTestCodeConfig: [],
                removerSpecimenSource: []
            }
        },
        components: {
            titlebar
        },
        created() {
            this.$http.get('/api/interface/config?id=' + this.$route.params.id)
                .then(res => {
                    this.model = res.data
                })
        },
        computed: {
            titleStack() {
                return [
                    'Interface',
                    'Configuração',
                    this.$route.params.id
                ]
            },
            connectionTypes() {
                return [
                    [0, 'Serial'],
                    [1, 'TCP'],
                    [2, 'File'],
                    [3, 'TCPCripto'],
                    [4, 'Database']
                ]
            },
            paridades() {
                return [
                    [0, 'Nenhuma'],
                    [1, 'Ímpar'],
                    [2, 'Par'],
                    [3, 'Marca'],
                    [4, 'Espaço']
                ]
            },
            handshakes() {
                return [
                    [0, 'Nenhum'],
                    [1, 'XOnXOff'],
                    [2, 'RTS'],
                    [3, 'RTS/XOnXOff']
                ]
            },
        },
        methods: {
            getAsyncCampos: debounce(function (name, item) {
                if (!name.length) {
                    this.listaCampos = []
                    return
                }
                this.isFetchingListaCampos = true
                let paramExameId = ''
                if (item.lisTestCode) {
                    paramExameId = `&exameId=${item.lisTestCode}`
                }
                this.$http.get(`/Search/MascaraCampo?nome=${name}` + paramExameId)
                    .then(({ data }) => {
                        this.listaCampos = data
                    })
                    .catch((error) => {
                        this.listaCampos = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetchingListaCampos = false
                    })
            }, 500),

            getMascaraCampoId(event, item) {
                if (item.lisResultCode == null)
                    return
                this.isFetchingListaCampos = true
                this.$http.get(`/Search/MascaraCampoId?id=${item.lisResultCode}`)
                    .then(({ data }) => {
                        this.selectCampo(data, item)
                    })
                    .catch((error) => {
                        throw error
                    })
                    .finally(() => {
                        this.isFetchingListaCampos = false
                    })
            },

            selectCampo(option, item) {
                item.lisResultCode = option.id
                item.lisResultName = option.nome
                item.lisTestCode = option.exameId
                item.lisTestName = option.exameNome
                item.lisResultVersion = option.mascaraVersao
                item.sampleNumber = option.recipienteId
                item.decimals = option.decimais
                item.units = option.unidade
            },

            getAsyncExames: debounce(function (name) {
                if (!name.length) {
                    this.listaExames = []
                    return
                }
                this.isFetchingListaExames = true
                this.$http.get(`/Search/Exame?texto=${name}`)
                    .then(({ data }) => {
                        this.listaExames = data
                    })
                    .catch((error) => {
                        this.listaExames = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetchingListaExames = false
                    })
            }, 500),

            getExameId(event, item) {
                if (item.lisTestCode == null)
                    return
                this.isFetchingListaExames = true
                this.$http.get(`/Search/ExameId?id=${item.lisTestCode}`)
                    .then(({ data }) => {
                        this.selectExame(data, item)
                    })
                    .catch((error) => {
                        throw error
                    })
                    .finally(() => {
                        this.isFetchingListaExames = false
                    })
            },

            selectExame(option, item) {
                item.lisTestCode = option.id
                item.lisTestName = option.nome
            },

            getAsyncMateriais: debounce(function (name) {
                if (!name.length) {
                    this.data = []
                    return
                }
                this.isFetchingListaMateriais = true
                this.$http.get(`/Search/Material?nome=${name}`)
                    .then(({ data }) => {
                        this.listaMateriais = data
                    })
                    .finally(() => {
                        this.isFetchingListaMateriais = false
                    })
            }, 500),

            submit() {
                this.$http.post('/api/interface/config', this.model)
                    .then(() => {
                        this.$router.push({ name: 'equipamentos' })
                    })
                    .catch(err => {
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar configuração do equipamento: ' + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
            }
        }
    }
</script>