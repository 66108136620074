<template>
    <section>
        <titlebar :title-stack="titleStack"> </titlebar>
        <b-loading :active.sync="salvandoDados"></b-loading>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="tile is-ancestor">                    
                    <div class="tile is-parent is-vertical is-9">
                        <article class="tile is-child box">                            
                            <div class="columns">                                
                                <div class="column is-4">
                                    <ValidationProvider name="Desconto" v-slot="{ errors, valid }">
                                        <b-field label="Desconto"
                                                    :message="errors"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-input v-model="model.desconto" @input="recalcularTotal()"></b-input>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <searchIdName class="column is-12" :id.sync="model.paciente.id" table="paciente" label="Paciente">
                                <slot>
                                    <b-button icon-left="plus-box"
                                              tag="router-link"
                                              :to="{ name: 'paciente', params: { return: true } }"
                                              title="Incluir um novo paciente"></b-button>
                                </slot>                                
                                </searchIdName>
                            </div>
                            <div class="columns">
                                <searchIdName class="column is-12" :id.sync="model.convenio.id" @item="escolheuConvenio" table="convenio" label="Convênio"/>
                            </div>
                            <div class="columns">
                                <searchIdName class="column is-12" :id.sync="model.medico.id" table="medico" label="Médico">
                                <slot>
                                    <b-button icon-left="plus-box"
                                              tag="router-link"
                                              :to="{ name: 'medico' }"
                                              title="Incluir um novo médico"></b-button>
                                </slot>          
                                </searchIdName>
                            </div>                            
                            <div class="columns">                                
                                <div class="column is-half-mobile">
                                    <ValidationProvider rules="required" name="Data de Emissao" v-slot="{ errors, valid }">
                                        <b-field label="Data de Emissao"
                                                    :message="errors"
                                                    :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-datepicker v-model="model.emissao" trap-focus open-on-focus></b-datepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                                <div class="column is-half-mobile">
                                    <ValidationProvider rules="required" name="data de validade" v-slot="{ errors, valid }">
                                        <b-field label="Data de Validade"
                                                 :message="errors"
                                                 :type="{ 'is-danger': errors[0], 'is-success': valid }">
                                            <b-datepicker editable v-model="model.validade" trap-focus open-on-focus></b-datepicker>
                                        </b-field>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">Exames</label>
                                            <span><b>Incluir exame</b></span>
                                            <div class="columns panel-block">

                                                <b-autocomplete class="column is-8 is-outlined is-fullwidth" :data="data"
                                                                ref="autocomplete"
                                                                v-model="autocomplete"
                                                                field="exameNome"
                                                                :loading="isFetching"
                                                                @typing="getAsyncExame"
                                                                icon="magnify"      
                                                                :readonly="model.guia != null && model.guia.id != null && model.guia.id != 0"                              
                                                                :disabled="model.guia != null && model.guia.id != null && model.guia.id != 0"                                    
                                                                @select="selectItem">
                                                    <template slot="empty">
                                                        Nenhum exame encontrado
                                                    </template>
                                                    <template slot-scope="props">
                                                        <div class="media">
                                                            <div class="media-left">
                                                                <span class="tag is-pulled-right is-light">{{ props.option.exameId }}</span>
                                                            </div>
                                                            <div class="media-content">
                                                                {{ props.option.exameNome }}
                                                                <br>
                                                                <small>
                                                                    <span>{{props.option.exameApelido}}</span>
                                                                </small>
                                                            </div>
                                                            <div class="media-right">
                                                                <b>Material: </b> {{ props.option.materialNome }} <br>
                                                                <b>Conservante: </b> {{ props.option.conservanteNome }} <br>
                                                                <b>Código: </b> {{ props.option.exameCodigo }} 
                                                            </div>                                    
                                                        </div>
                                                    </template>
                                                </b-autocomplete>

                                                <div class="column is-1 is-outlined is-fullwidth">
                                                    <b-dropdown native-type="button" :triggers="['hover']" aria-role="list" expanded>
                                                        <button type="button" native-type="button" class="button is-info is-fullwidth" slot="trigger">
                                                            <b-icon icon="menu-down"></b-icon>
                                                        </button>
                                                        <b-dropdown-item aria-role="listitem" @click="procurarExamePor = 'Apelido'">Apelido</b-dropdown-item>
                                                        <b-dropdown-item aria-role="listitem" @click="procurarExamePor = 'Nome'">Nome</b-dropdown-item>
                                                    </b-dropdown>
                                                </div>

                                                <b-button v-if="gruposExames.length > 0" type="button" class="column is-3 is-success" 
                                                    @click.prevent.stop="grupos = !grupos">
                                                    Grupo de Exame
                                                </b-button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <b-table :data="model.exames" striped hoverable>
                                        <template slot-scope="props">                                            
                                            <b-table-column field="" label="">
                                                <b-button
                                                    icon-left="close"
                                                    type="is-danger"
                                                    @click.prevent.stop="removerExame(props.row)"
                                                ></b-button>
                                            </b-table-column>

                                            <b-table-column field="exameApelido" label="Apelido">
                                                {{ props.row.exameApelido }}
                                            </b-table-column>

                                            <b-table-column field="examePreco" label="Preço">
                                                {{ props.row.examePreco | currency  }}
                                            </b-table-column>
                                        </template>
                                        <template slot="footer">
                                            <th class="is-hidden-mobile">Total de Exames: {{model.exames.length}}</th>
                                            <th class="is-hidden-mobile"></th>
                                            <th class="is-hidden-mobile">
                                                {{ totalExamesDesconto | currency }}                                                
                                            </th>
                                        </template>
                                        </b-table>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <label class="label">Observações</label>
                                        <div class="control">
                                            <textarea class="textarea" v-model="model.observacoes"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    
                    <div class="tile is-parent">
                        <article class="tile is-child">
                            <historico-alteracoes :tabela="'Orcamento'" :chave="model.id" v-if="model.id"></historico-alteracoes>

                            <div class="panel-block" v-if="model.id">
                                <b-dropdown native-type="button" :triggers="['hover']" aria-role="list" expanded>
                                    <button type="button" native-type="button" class="button is-info is-fullwidth" slot="trigger">
                                        <span>Imprime</span>
                                        <b-icon icon="menu-down"></b-icon>
                                    </button>
                                    <b-dropdown-item aria-role="listitem" @click="modeloOrcamento(modelo.nome)" v-for="(modelo, x) in model.orcamentoModelos" v-bind:key="x">{{modelo.nome}}</b-dropdown-item>
                                    <b-dropdown-item aria-role="listitem" @click="enviarEmail()">Enviar por E-mail</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <br>
                            <div class="panel-block" v-if="new Date(model.validade) < Date.now() && model.guia.id == null">
                                <b-button type="button is-tag is-danger" class="button is-danger is-fullwidth">Orçamento Expirado</b-button>
                            </div>
                            <div class="panel-block" v-show="model.id != null && model.id != 0" >
                                <b-button type="is-success" class="button is-success is-fullwidth" v-if="model.id != null && model.id != 0 && (model.guia == null || model.guia.id == null || model.guia.id == 0) && isInRole('orcamento-criaGuia') && new Date(model.validade) >= Date.now()" 
                                      :loading="salvandoDados"
                                      native-type="button"
                                      icon-left="check-circle"
                                      @click="criarGuia()">
                                    Criar Guia
                                </b-button>
                                <b-button expanded v-else-if="model.id != null && model.id != 0 && model.guia != null && model.guia.id != null && model.guia.id != 0"	
                                        tag="router-link"                          
                                        :to="{ name: 'guia', params: {id: model.guia.id} }"                          
                                        type="is-primary"> {{model.guia.id}}
                                </b-button>  
                            </div>
                            <br>
                            <div class="box" v-if="grupos">
                                <article style="cursor: pointer;" class="box is-info column is-12" @click="incluirGrupo(grupo)" v-for="(grupo, x) in gruposExames" v-bind:key="x">
                                    <label class="label has-text-centered">{{grupo.nome}}</label>
                                    <div class="columns is-multiline">
                                        <div class="column" v-for="(item, x) in grupo.grupoItens" v-bind:key="x">
                                            <small class="bd-notification is-primary">{{item.exame.apelido}}</small>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </article>
                    </div>
                </div>
                <nav class="level">
                    <div class="level-item">
                        <p class="buttons">
                            <b-button type="is-success" v-if="model.id == 0 || (model.validadeInicial > Date.now() && model.validadeInicial !=null )"
                                      :loading="salvandoDados"
                                      native-type="submit"
                                      icon-left="check-circle">
                                Salvar
                            </b-button>
                            <b-button type="is-danger"
                                      native-type="button"
                                      icon-left="close-circle"
                                      @click="$router.back();">
                                Cancelar
                            </b-button>
                        </p>
                    </div>
                </nav>
            </form>
        </ValidationObserver>
    </section>
</template>

<script>
    import debounce from 'lodash/debounce'
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import historicoAlteracoes from '@/components/historicoAlteracoes.vue'

    export default {
        data() {
            return {
                model: {
                    id: 0,
                    nome: null,
                    imprimirOrcamentoModelo: '',
                    enviaPorEmail: false,
                    validadeInicial: null,
                    email: '',
                    paciente: {
                        id: null
                    },
                    convenio: {
                        id: null
                    },
                    medico: {
                        id: null
                    },
                    guia: {
                        id: null
                    },
                    exames: []
                },
                data: [],
                gruposExames: [],
                procurarExamePor: 'Apelido',
                salvandoDados: false,
                totalExames: 0,
                isFetching: false,
                grupos: false,
                autocomplete: null
            };
        },
        components: {
            titlebar,  
            historicoAlteracoes,
            searchIdName          
        },
        created() {
            if (this.$route.params.id) {
                this.salvandoDados = true;
                this.$http.get('/api/atendimento/orcamento?id=' + this.$route.params.id)
                    .then(res => {
                        this.model = res.data;   
                        this.model.emissao = new Date(this.model.emissao);
                        this.model.validade = new Date(this.model.validade);
                        this.model.validadeInicial = this.model.validade;                        
                        this.recalcularTotal();
                        this.salvandoDados=false;
                    })

            }else{
                
                this.model.emissao =  new Date();
                this.model.validade = new Date(Date.now() + (30 * 24 * 60 * 60 * 1000));
   
            }
            
            this.$http.get('/api/search/gruposexames')
                .then(res => {
                    this.gruposExames = res.data;
                });
        },
        computed: {
            titleStack() {
                return [
                    'Atendimento',
                    'Orçamento',
                    this.model.id ?? 'novo'
                ]
            },          
            ...mapState([
                'unidadeId',
                'config'
            ]),
            ...mapGetters([
                'isInRole'
            ]),
            totalExamesDesconto(){
                if(this.model.desconto != null && this.model.desconto != '' && this.model.desconto != undefined) {                    
                    if((this.model.desconto + "").indexOf("%") != -1) {
                        return (parseFloat(this.totalExames) - 
                            (parseFloat(this.totalExames) * 
                            (parseFloat((this.model.desconto + "").replace("%", "").replace(",", ".")) / 100) ) );
                    } else {
                        return (parseFloat(this.totalExames) - parseFloat((this.model.desconto + "").replace(",", ".")));
                    }
                }

                return this.totalExames;
            }
        },
        methods: {
            onSubmit() {     
                if(this.model.paciente.id == null || this.model.paciente.id == '' || this.model.paciente.id == 0){
                    this.$buefy.dialog.alert({
                        title: 'Paciente obrigatório',
                        message: 'Especifique um paciente para continuar.',
                        type: 'is-warning',
                        hasIcon: true
                    });
                    return;
                }
           
                if(this.model.desconto != null && this.model.desconto != '' && this.model.desconto != undefined) { 
                    if((this.model.desconto + "").indexOf("%") != -1) {
                        this.model.desconto = parseFloat(this.totalExames) - parseFloat(this.totalExamesDesconto)
                    }
                    else {
                        this.model.desconto = (this.model.desconto + "").replace(",", ".")
                    }
                }

                const newItem = Object.assign({}, this.model);  

                this.salvandoDados = true;
                this.$http.post('/api/atendimento/orcamento', newItem)
                    .then(res => res.data)
                    .then(data => {
                        console.log(data);
                        //this.$router.push({ name: 'atendimento' })
                    })
                    .catch(err => {
                        console.error(err)
                        Object.keys(err.body.errors).forEach(x => {
                            this.$store.commit('ADD_ERRO', x + ": " + err.body.errors[x])
                        })
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar orcamento: ' + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
                    .finally(() => {
                        this.salvandoDados = false;
                        this.$router.back()
                    })
            },
            getAsyncExame: debounce(function (name) {
                if (!name.length) {
                    this.data = []
                    return
                }
                this.isFetching = true
                this.$http.get(`/Search/OrcamentoExame?nome=${name}&tipo=${this.procurarExamePor}&convenioId=${this.model.convenio.id}`)
                    .then(({ data }) => {
                        this.data = []
                        data.forEach((item) => this.data.push(item))
                    })
                    .catch((error) => {
                        this.data = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            enviarEmail(){
                this.$buefy.dialog.prompt({
                    title: 'Enviar orçamento por email',
                    message: 'Enviar orçamento por email',
                    inputAttrs: {
                        type: 'text',
                        placeholder: 'Email'
                    }, 
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.model.email = res;
                        this.model.enviaPorEmail = true;
                        this.onSubmit();
                    }
                })                
            },
            incluirGrupo(grupo){
                grupo.grupoItens.forEach((item) => {                    
                    this.$http.get(`/api/Search/OrcamentoExameId?id=${item.exame.id}&convenioId=${this.model.convenio.id}`)
                        .then(({ data }) => {
                            data.forEach((item) => this.model.exames.push(item));
                            this.recalcularTotal();
                        })
                });
            },
            modeloOrcamento(modelo) {
                let url = `/api/atendimento/ImprimeOrcamento?id=${this.model.id}`;
                if(modelo != null && modelo != '' && modelo != undefined){
                    url += "&modelo=" + modelo;
                }
                
                window.open(url, '_blank');
            },
            removerExame(exame){      
                if(this.model.examesRemovidos == null){
                    this.model.examesRemovidos = [];
                }
                this.model.examesRemovidos.push(this.model.exames?.filter((i) => i.exameId == exame.exameId)[0]);
                this.model.exames = this.model.exames?.filter((i) => i.exameId != exame.exameId);
                this.recalcularTotal();
            },
            recalcularTotal() {
                this.totalExames = 0;
                this.model.exames.forEach((v) => {
                    this.totalExames += parseFloat(v.examePreco?.toString());
                });
                this.totalExames = this.totalExames.toFixed(2);                
            },
            selectItem(option) {
                if(option != null){
                    this.model.exames.push({
                        bloqueado: option.bloqueado,
                        exameApelido: option.exameApelido,
                        exameCodigo: option.exameCodigo,
                        exameId: option.exameId,
                        exameNome: option.exameNome,
                        examePreco: option.preco,
                        recipienteId: option.recipienteId,
                        materialNome: option.materialNome,
                        conservanteNome: option.conservanteNome,
                        id: 0
                    });

                    this.recalcularTotal();

                }
                
                setTimeout(() => this.autocomplete = '', 10);

            },
            escolheuConvenio(val){
                if(val.data.desconto && val.data.desconto > 0 ){
                    this.model.desconto = val.data.desconto + "%";
                }else{
                    this.model.desconto = 0.0; 
                }

                this.model.exames.forEach((exame) => {
                    this.$http.get(`/api/Search/OrcamentoExameId?id=${exame.exameId}&convenioId=${this.model.convenio.id}`)
                        .then(({ data }) => {
                            data.forEach((item) => {
                                if(item.exameId == exame.exameId)
                                {
                                    exame.examePreco = item.examePreco;
                                }
                            });
                            this.recalcularTotal();
                        })
                });
                
            },
            criarGuia(){
                this.salvandoDados = true;
                this.$http.get(`/api/atendimento/OrcamentoParaGuia?orcamentoId=${this.model.id}&medicoId=${this.model.medico.id}&unidadeId=${this.unidadeId}&localDeOrigemId=${this.model.localOrigemId}`)
                    .then(res => res.data)
                    .then(data => {
                        if(data.sucesso){
                            this.$router.push({ name: 'guia', params: { id: data.id }});
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.$buefy.toast.open({
                            duration: 5000,
                            message: 'Erro ao salvar orcamento: ' + err.body.title,
                            type: 'is-danger',
                            queue: false
                        })
                    })
                    .finally(() => {
                        this.salvandoDados = false;
                    })
            }
        }
    }
</script>