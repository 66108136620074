<template>
    <div class="column is-3">
        <div :class="classes" @click.capture="selecionar">
            <div class="column is-5">
                <h3 class="size-3">
                    <a :title="guiaexame.item + ' - ' +  guiaexame.exameNome"
                       v-if="!portal && guiaexame.materialEntregue && !guiaexame.cancelado && !guiaexame.bloqueado && guiaexame.item != 0 && isInRole('resultado-editar')"
                       :href="'/guia/resultado?guiaId=' + guia.id + '&item=' + guiaexame.item">
                        {{ guiaexame.exameApelido }}
                    </a>
                    <span :title="guiaexame.item + ' - ' +  guiaexame.exameNome" v-else>{{ guiaexame.exameApelido }}</span>
                    <router-link v-if="guiaexame.copiaDe"
                                 class="is-pulled-right"
                                 :title="'Resultado de exame vinculado ao item ' + guiaexame.copiaDe.item + ' da guia ' + guiaexame.copiaDe.guiaId"
                                 :to="{ name: 'guia', params: { id: guiaexame.copiaDe.guiaId }}">
                        <b-icon icon="link"></b-icon>
                    </router-link>
                    <b-icon v-if="guiaexame.incidencia"
                            icon="alert-rhombus"
                            size="is-medium"
                            class="is-pulled-right"
                            title="Exame com incidência"
                            type="is-warning"></b-icon>
                </h3>
                <div v-if="guiaexame.item == 0">
                    <div class="field">
                        <div class="control">
                            <input class="input is-small" type="text" maxlength="5" v-model="guiaexame.observacao">
                        </div>
                    </div>
                </div>
                <div v-else>
                    <b-tag v-if="guiaexame.observacao">{{ guiaexame.observacao }}</b-tag>
                </div>
            </div>
            <div class="column has-text-centered">
                <p class="is-size-7">{{ guiaexame.dataDeSolicitacao | moment('DD/MM/YY') }}</p>
                <p class="is-size-7">{{ guiaexame.dataDeSolicitacao | moment('HH:mm') }}</p>
            </div>
            <div v-if="guiaexame.item == 0" class="column is-flex">
                <button class="delete is-small is-pulled-right has-background-danger" title="Excluir o exame" @click.stop="$emit('excluirExame', index)"></button>
                <b-button :class="{ 'is-warning': guiaexame.urgente }"
                          v-if="!guiaexame.exameDeFaturamento"
                          icon-left="alert-circle"
                          size="is-small"
                          title="Clique ligar/desligar a urgência"
                          @click="setUrgente"></b-button>
                <b-button :class="{ 'is-link': guiaexame.materialEntregue }"
                          v-if="!guiaexame.exameDeFaturamento  && !portal"
                          icon-left="water"
                          size="is-small"
                          title="Clique ligar/desligar o recebimento do material"
                          @click="setMaterialEntregue"></b-button>
                <b-button  v-if="guiaexame.requerAutorizacao"
                          icon-left="asterisk"
                          size="is-small"
                          title="Este exame requer autorização do convênio!">
                          </b-button>                          
            </div>
            <div v-if="guiaexame.item > 0 && !guiaexame.exameDeFaturamento" class="column is-flex">
                <b-button :class="{ 'is-warning': guiaexame.urgente }"
                          icon-left="alert-circle"
                          size="is-small"
                          title="Clique ligar/desligar a urgência"
                          @click="setUrgente"></b-button>
                <b-button  v-if="guiaexame.requerAutorizacao"
                          icon-left="asterisk"
                          size="is-small"
                          title="Este exame requer autorização do convênio!">
                          </b-button>                            
            </div>
            <div v-if="guiaexame.item > 0 && (!guiaexame.exameDeFaturamento || guiaexame.cancelado)" class="column" :title="guiaexame.statusTitle">
                <div class="has-text-weight-bold has-text-centered" :style="guiaexame.statusHtmlStyle">
                    {{ guiaexame.statusSigla }}
                </div>
            </div>
            <div v-if="guiaexame.item > 0 && guiaexame.exameDeFaturamento" class="column" title="Exame de Faturamento">
                <div class="has-text-weight-bold has-text-centered">
                    F
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: {
            guiaexame: Object,
            guia: Object,
            index: Number,
            selecionado: Boolean,
            portal: Boolean
        },
        data() {
            return {
                classes: this.defineClasses()
            };
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ])
        },
        watch: {
            selecionado: function () {
                this.classes = this.defineClasses()
            }
        },
        methods: {
            defineClasses() {
                return "columns is-multiline is-mobile guiaExame" +
                    (this.$props.selecionado ? " guiaExameSelecionado" : "")
            },
            setUrgente() {
                this.guiaexame.urgente = !this.guiaexame.urgente
                localStorage.caraterUrgencia = this.guiaexame.urgente
            },
            setMaterialEntregue() {
                this.guiaexame.materialEntregue = !this.guiaexame.materialEntregue
                localStorage.materialEntregue = this.guiaexame.materialEntregue
            },
            selecionar() {
                if (this.guiaexame.item != 0) {
                    this.$emit('selecionarExame', this.guiaexame)
                }
            }
        }
    }
</script>