<template>
    <section>

        <boasVindas></boasVindas>

        <pesquisas :pesquisas="pesquisas" :tipo="'Paciente'"></pesquisas>

        <div class="box is-hero-bar is-main-hero" v-if="model != null && model.temPendencias">
            <div class="hero-body">
                <div class="level">
                    <div class="level-left">
                        <div class="level-item is-hero-content-item">
                            <div>
                                <p>{{model.frasePendenciaFinanceiraPacienteWeb}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isLoading">
            <div class="box">
                <div class="panel">
                    <div class="panel-block columns is-multiline is-mobile">
                        <div class="column is-10-desktop">
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-6-desktop is-full-mobile">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                                <div class="column is-2-desktop is-half-mobile">
                                    <b-skeleton width="60%" animated></b-skeleton>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <b-skeleton width="30%" animated></b-skeleton>
                                </div>
                                <div class="column is-2-desktop is-half-mobile">
                                    <b-skeleton width="60%" animated></b-skeleton>
                                </div>
                                <div class="column is-4-desktop is-half-mobile">
                                </div>
                            </div>
                            <div class="columns is-multiline is-mobile">
                                <div class="column is-full-mobile">
                                    <b-skeleton width="80%" animated></b-skeleton>
                                </div>
                            </div>
                        </div>
                        <div class="column is-full-mobile has-text-centered">
                            <div class="columns is-multiline is-mobile">
                                <b-skeleton width="80%" animated></b-skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <b-tabs v-model="tabs" multiline>
                <b-tab-item label="Análises clínicas">
                    <guias v-if="model != null" :usuarioPacienteId="model.usuarioPacienteId" :lista.sync="model.guias" @pageChange="pageChange($event)" :tipo="'Paciente'"
                        :page.sync="page" :pageCount="pageCount"></guias>
                </b-tab-item>
                <b-tab-item v-if="config.integracaoRis" label="Radiologia">
                    <div class="box is-hero-bar is-main-hero" v-for="(report, index) in listaRadiologia" :key="index">
                        <div class="panel-block columns is-multiline is-mobile">                        
                            <div class="column is-10-desktop">
                                <div class="columns is-multiline is-mobile">
                                    <div class="column is-6-desktop is-full-mobile">
                                        <small>Número de acesso:</small>
                                        <br />
                                        <b>{{ report.accession_no }}</b>
                                    </div>
                                    <div class="column is-6-desktop is-full-mobile">
                                        <small>Data de emissão:</small>
                                        <br />
                                        <b>{{ report.study_datetime }}</b>
                                    </div>
                                </div>
                                <div class="columns is-mobile is-multiline">
                                    <div>
                                        <b-tag type="is-success">
                                            {{ report.procedure }}
                                        </b-tag> 
                                    </div>
                                </div>
                            </div>
                            <div class="column is-full-mobile has-text-centered">
                                <div class="columns is-multiline is-mobile">
                                    <b-button icon-left="file-document-outline"
                                        class="column is-info is-12"
                                        @click="reportResult(report.report)"
                                        label="Resultado"></b-button>
                                    <b-button icon-left="eye"
                                        class="column is-primary is-12"
                                        @click="viewer(report.viewer_link)"
                                        label="Viewer"></b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab-item>
            </b-tabs>
        </div>
    </section> 
</template>

<script>
    import { mapState } from 'vuex'
    import boasVindas from '@/components/portal/boas-vindas.vue'
    import pesquisas from '@/components/portal/pesquisas.vue'
    import guias from '@/components/portal/guias.vue'

    export default {
        data() {
            return {
                model: null,
                page: 1,
                pageCount: null,
                pesquisas: [],
                isLoading: false,
                tabs: 0,
                listaRadiologia: []
            }
        },
        computed: {
            ...mapState([
                'config',
                'usuario'
            ])
        },
        components: {
            boasVindas,
            pesquisas,
            guias
        },
        methods: {
            carregarLista(filtrosUrl) {
                this.isLoading = true;
                this.$http.get('/api/portal/p' + filtrosUrl)
                    .then(res => {
                        this.model = res.data;
                        this.page = this.model.page;
                        this.pageCount = this.model.pageCount;
                        this.isLoading = false;
                    });
            },
            carregarRadiologia(){
                this.$http.get('/api/portal/ExamesRadiologia')
                    .then(res => {
                        this.listaRadiologia = res.data;
                    });
            },
            reportResult(report){
                window.open(report);
            },
            viewer(viewer){
                window.open(viewer);
            },
            carregarPesquisas() {
                this.$http.get('/api/portal/PacientePesquisasSatisfacao')
                    .then(res => {
                        this.pesquisas = res.data;
                    });
            },
            pageChange(page) {
                this.page = page;
                this.carregarLista('?page=' + this.page);
            }
        },
        created() {
            this.carregarLista('?page=' + this.page);
            this.carregarPesquisas();

            if(this.config.integracaoRis)
            {
                this.carregarRadiologia();
            }
        }
    }
</script>